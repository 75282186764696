import { Container } from "../components/Container";

const faqs = [
  [
    {
      question: "How do I start building a website with the app?",
      answer:
        "Simply download the app, create an account, and follow the guided steps to start building your website. You can choose from a variety of templates and customize them to fit your needs.",
    },
    {
      question: "Can I use the app to build an e-commerce site?",
      answer:
        "Yes, our app supports e-commerce functionality. You can add product listings, set up payment gateways, and manage orders directly from the app.",
    },
    {
      question: "Is coding knowledge required to use the app?",
      answer:
        "No coding knowledge is required. Our app is designed to be user-friendly, with drag-and-drop features and customizable templates that make website building easy for everyone.",
    },
  ],
  [
    {
      question: "Can I integrate third-party services into my website?",
      answer:
        "Yes, you can integrate various third-party services such as Google Analytics, social media platforms, and more to enhance your website's functionality.",
    },
    {
      question: "Is my website mobile-friendly?",
      answer:
        "Absolutely! All websites built with our app are responsive and optimized for mobile devices, ensuring a seamless experience for your visitors.",
    },
    {
      question: "How do I publish my website?",
      answer:
        "Once you're satisfied with your website, you can publish it directly from the app. We offer hosting services, or you can connect your own domain.",
    },
  ],
  [
    {
      question: "What support options are available if I need help?",
      answer:
        "We offer 24/7 customer support through chat and email. You can also access our comprehensive help center for tutorials and FAQs.",
    },
    {
      question: "Can I collaborate with others on my website?",
      answer:
        "Yes, you can invite team members to collaborate on your website. Assign roles and permissions to manage who can edit and publish content.",
    },
    {
      question:
        "Are there any limitations on the number of websites I can build?",
      answer:
        "With our premium plan, you can build and manage multiple websites. The free plan allows you to create one website with basic features.",
    },
  ],
];

export function Faqs() {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="border-t border-gray-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-gray-900"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            If you have anything else you want to ask,{" "}
            <a
              href="mailto:contact@venmiga.com"
              className="text-gray-900 underline"
            >
              reach out to us
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
