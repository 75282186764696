import { CallToAction } from "landing/components/CallToAction";
import { Faqs } from "landing/components/Faqs";
import { Hero } from "landing/components/Hero";
import { Pricing } from "landing/components/Pricing";
import { PrimaryFeatures } from "landing/components/PrimaryFeatures";
import { Reviews } from "landing/components/Reviews";
import { SecondaryFeatures } from "landing/components/SecondaryFeatures";
import { Footer } from "landing/Footer";
import { Header } from "landing/Header";
import "styles/output.css";

export default function VenmigaLanding() {
  return (
    <>
      <Header />
      <Hero />
      <PrimaryFeatures />
      <CallToAction />
      <Pricing />
      <Faqs />
      <Footer />
    </>
  );
}
