import React, { useEffect, useState } from "react";
import axios from "axios";
import BackgroundAsImage from "components/hero/BackgroundAsImage";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";
import FullWidthWithImage from "components/hero/FullWidthWithImage";
import TwoColumnWithFeaturesAndTestimonial from "components/hero/TwoColumnWithFeaturesAndTestimonial";
import TwoColumnWithInput from "components/hero/TwoColumnWithInput";
import TwoColumnWithPrimaryBackground from "components/hero/TwoColumnWithPrimaryBackground";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import ThreeColCenteredStatsPrimaryBackground from "components/features/ThreeColCenteredStatsPrimaryBackground";
import ThreeColSimple from "components/features/ThreeColSimple";
import ThreeColWithSideImage from "components/features/ThreeColWithSideImage";
import ThreeColWithSideImageWithPrimaryBackground from "components/features/ThreeColWithSideImageWithPrimaryBackground";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColSingleFeatureWithStats2 from "components/features/TwoColSingleFeatureWithStats2";
import TwoColWithButton from "components/features/TwoColWithButton";
import TwoColWithSteps from "components/features/TwoColWithSteps";
import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons";
import TwoColWithTwoHorizontalFeaturesAndButton from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import VerticalWithAlternateImageAndText from "components/features/VerticalWithAlternateImageAndText";
import TabCardGrid from "components/cards/TabCardGrid";
import SimplePrimaryBackground from "components/testimonials/SimplePrimaryBackground";
import ThreeColumnWithProfileImage from "components/testimonials/ThreeColumnWithProfileImage";
import TwoColumnWithImage from "components/testimonials/TwoColumnWithImage";
import TwoColumnWithImageAndProfilePictureReview from "components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import TwoColumnWithImageAndRating from "components/testimonials/TwoColumnWithImageAndRating";
import DownloadApp from "components/cta/DownloadApp";
import GetStarted from "components/cta/GetStarted";
import GetStartedLight from "components/cta/GetStartedLight";
import FiveColumnDark from "components/footers/FiveColumnDark";
import FiveColumnWithBackground from "components/footers/FiveColumnWithBackground";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import GridWithFeaturedPost from "components/blogs/GridWithFeaturedPost";
import PopularAndRecentBlogPosts from "components/blogs/PopularAndRecentBlogPosts";
import ThreeColSimpleWithImage from "components/blogs/ThreeColSimpleWithImage";
import ThreeColSimpleWithImageAndDashedBorder from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";
import PortfolioTwoCardsWithImage from "components/cards/PortfolioTwoCardsWithImage";
import ProfileThreeColGrid from "components/cards/ProfileThreeColGrid";
import ThreeColContactDetails from "components/cards/ThreeColContactDetails";
import ThreeColSlider from "components/cards/ThreeColSlider";
import TwoTrendingPreviewCardsWithImage from "components/cards/TwoTrendingPreviewCardsWithImage";
import SimpleWithSideImage from "components/faqs/SimpleWithSideImage";
import SingleCol from "components/faqs/SingleCol";
import TwoColumnPrimaryBackground from "components/faqs/TwoColumnPrimaryBackground";
import SimpleContactUs from "components/forms/SimpleContactUs";
import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";
import TwoColContactUsWithIllustration from "components/forms/TwoColContactUsWithIllustration";
import ThreePlans from "components/pricing/ThreePlans";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground";
import TwoPlansWithDurationSwitcher from "components/pricing/TwoPlansWithDurationSwitcher";
import VenmigaLanding from "VenmigaLanding";

const Venmiga = () => {
  const [componentData, setComponentData] = useState([]);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const isLocalhost =
    window.location.hostname === "venmiga.com" ||
    window.location.hostname === "www.venmiga.com";

  useEffect(() => {
    if (!isLocalhost) {
      axios
        .get(
          `https://api.venmiga.com/api/v1/sites/check-url/${window.location.hostname}`
        )
        .then((fback) => {
          axios
            .get(
              `https://api.venmiga.com/api/v1/pages/component/${fback.data.id}`
            )
            .then((response) => {
              setComponentData(response.data.components);
              setPages(response.data.page);
            })
            .catch((error) => {
              console.error(
                "There was an error fetching the site data!",
                error
              );
            })
            .finally(() => {
              setLoading(false);
            });
        });
    }
  }, []);

  // Check if the current URL is localhost:3001

  return (
    <div>
      {isLocalhost && <VenmigaLanding />}
      {loading ? (
        <>Loading...</>
      ) : (
        componentData.map((component) => {
          if (component.name === "hero") {
            if (component.version === 1) {
              return (
                <BackgroundAsImage
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  primaryActionLink={component.hero.primaryActionLink}
                  secondaryAction={component.hero.secondaryAction}
                  secondaryActionLink={component.hero.secondaryActionLink}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  imageUrl={component.hero.imageUrl}
                  showSecondaryAction={component.hero.showSecondaryAction}
                  navs={pages}
                />
              );
            } else if (component.version === 2) {
              return (
                <BackgroundAsImageWithCenteredContent
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  navs={pages}
                />
              );
            } else if (component.version === 3) {
              return (
                <FullWidthWithImage
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  navs={pages}
                />
              );
            } else if (component.version === 4) {
              return (
                <TwoColumnWithFeaturesAndTestimonial
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  navs={pages}
                />
              );
            } else if (component.version === 5) {
              return (
                <TwoColumnWithInput
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  navs={pages}
                />
              );
            } else if (component.version === 6) {
              return (
                <TwoColumnWithPrimaryBackground
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  navs={pages}
                />
              );
            } else if (component.version === 7) {
              return (
                <TwoColumnWithVideo
                  heading={component.hero.heading}
                  notification={component.hero.notification}
                  primaryAction={component.hero.primaryAction}
                  showHeading={component.hero.showHeading}
                  showNotification={component.hero.showNotification}
                  showPrimaryAction={component.hero.showPrimaryAction}
                  showSlantedBackground={component.hero.showSlantedBackground}
                  showVideo={component.hero.showVideo}
                  slantedBackground={component.hero.slantedBackground}
                  videoURL={component.hero.videoURL}
                  navs={pages}
                />
              );
            }
          } else if (component.name === "feature") {
            if (component.version === 1) {
              return (
                <DashedBorderSixFeatures
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 2) {
              return (
                <ThreeColCenteredStatsPrimaryBackground
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 3) {
              return (
                <ThreeColSimple
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 4) {
              return (
                <ThreeColWithSideImage
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 5) {
              return (
                <ThreeColWithSideImageWithPrimaryBackground
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 6) {
              return (
                <TwoColSingleFeatureWithStats
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 7) {
              return (
                <TwoColSingleFeatureWithStats2
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 8) {
              return (
                <TwoColWithButton
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 9) {
              return (
                <TwoColWithSteps
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 10) {
              return (
                <TwoColWithTwoFeaturesAndButtons
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 11) {
              return (
                <TwoColWithTwoHorizontalFeaturesAndButton
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            } else if (component.version === 12) {
              return (
                <VerticalWithAlternateImageAndText
                  imageUrl={component.features.image}
                  title={component.features.title}
                  description={component.features.description}
                  url={component.features.url}
                  urlText={component.features.urlText}
                />
              );
            }
          } else if (component.name === "shop") {
            return (
              <TabCardGrid
                image={component.shop.image}
                title={component.shop.title}
                content={component.shop.content}
                price={component.shop.price}
                rating={component.shop.rating}
                reviews={component.shop.reviews}
                url={component.shop.url}
                category={component.shop.category}
              />
            );
          } else if (component.name === "blog") {
            if (component.version === 1) {
              return <GridWithFeaturedPost />;
            } else if (component.version === 2) {
              return <PopularAndRecentBlogPosts />;
            } else if (component.version === 3) {
              return <ThreeColSimpleWithImage />;
            } else if (component.version === 4) {
              return <ThreeColSimpleWithImageAndDashedBorder />;
            }
          } else if (component.name === "card") {
            if (component.version === 1) {
              return <PortfolioTwoCardsWithImage />;
            } else if (component.version === 2) {
              return <ProfileThreeColGrid />;
            } else if (component.version === 3) {
              return <TabCardGrid />;
            } else if (component.version === 4) {
              return <ThreeColContactDetails />;
            } else if (component.version === 4) {
              return <ThreeColSlider />;
            } else if (component.version === 4) {
              return <TwoTrendingPreviewCardsWithImage />;
            }
          } else if (component.name === "faq") {
            if (component.version === 1) {
              return <SimpleWithSideImage />;
            } else if (component.version === 2) {
              return <SingleCol />;
            } else if (component.version === 3) {
              return <TwoColumnPrimaryBackground />;
            }
          } else if (component.name === "forms") {
            if (component.version === 1) {
              return <SimpleContactUs />;
            } else if (component.version === 2) {
              return <SimpleSubscribeNewsletter />;
            } else if (component.version === 3) {
              return <TwoColContactUsWithIllustration />;
            }
          } else if (component.name === "pricing") {
            if (component.version === 1) {
              return <ThreePlans />;
            } else if (component.version === 2) {
              return <ThreePlansWithHalfPrimaryBackground />;
            } else if (component.version === 3) {
              return <TwoPlansWithDurationSwitcher />;
            }
          } else if (component.name === "testimonial") {
            if (component.version === 1) {
              return (
                <SimplePrimaryBackground
                  subheading={component.testimonial.subheading}
                  heading={component.testimonial.heading}
                  description={component.testimonial.description}
                />
              );
            } else if (component.version === 2) {
              return (
                <ThreeColumnWithProfileImage
                  subheading={component.testimonial.subheading}
                  heading={component.testimonial.heading}
                  description={component.testimonial.description}
                />
              );
            } else if (component.version === 3) {
              return (
                <TwoColumnWithImage
                  subheading={component.testimonial.subheading}
                  heading={component.testimonial.heading}
                  description={component.testimonial.description}
                />
              );
            } else if (component.version === 4) {
              return (
                <TwoColumnWithImageAndProfilePictureReview
                  subheading={component.testimonial.subheading}
                  heading={component.testimonial.heading}
                  description={component.testimonial.description}
                />
              );
            } else if (component.version === 5) {
              return (
                <TwoColumnWithImageAndRating
                  subheading={component.testimonial.subheading}
                  heading={component.testimonial.heading}
                  description={component.testimonial.description}
                />
              );
            }
          }
          if (component.name === "cta") {
            if (component.version === 1) {
              return (
                <DownloadApp
                  subheading={component.cta.subheading}
                  text={component.cta.text}
                  link1text={component.cta.link1text}
                  link1Icon={component.cta.link1Icon}
                  link1Url={component.cta.link1Url}
                  link2text={component.cta.link2text}
                  link2Icon={component.cta.link2Icon}
                  link2Url={component.cta.link2Url}
                  pushDownFooter={component.cta.pushDownFooter}
                  image={component.cta.image}
                  url={component.cta.url}
                />
              );
            } else if (component.version === 2) {
              return (
                <GetStarted
                  subheading={component.cta.subheading}
                  text={component.cta.text}
                  link1text={component.cta.link1text}
                  link1Icon={component.cta.link1Icon}
                  link1Url={component.cta.link1Url}
                  link2text={component.cta.link2text}
                  link2Icon={component.cta.link2Icon}
                  link2Url={component.cta.link2Url}
                  pushDownFooter={component.cta.pushDownFooter}
                  image={component.cta.image}
                  url={component.cta.url}
                />
              );
            } else if (component.version === 3) {
              return (
                <GetStartedLight
                  subheading={component.cta.subheading}
                  text={component.cta.text}
                  link1text={component.cta.link1text}
                  link1Icon={component.cta.link1Icon}
                  link1Url={component.cta.link1Url}
                  link2text={component.cta.link2text}
                  link2Icon={component.cta.link2Icon}
                  link2Url={component.cta.link2Url}
                  pushDownFooter={component.cta.pushDownFooter}
                  image={component.cta.image}
                  url={component.cta.url}
                />
              );
            }
          }
          if (component.name === "footer") {
            if (component.version === 1) {
              return (
                <FiveColumnDark
                  image={component.footer.image}
                  description={component.footer.description}
                />
              );
            } else if (component.version === 2) {
              return (
                <FiveColumnWithBackground
                  image={component.footer.image}
                  description={component.footer.description}
                />
              );
            } else if (component.version === 3) {
              return (
                <FiveColumnWithInputForm
                  image={component.footer.image}
                  description={component.footer.description}
                />
              );
            } else if (component.version === 4) {
              return (
                <MiniCenteredFooter
                  image={component.footer.image}
                  description={component.footer.description}
                />
              );
            } else if (component.version === 5) {
              return (
                <SimpleFiveColumn
                  image={component.footer.image}
                  description={component.footer.description}
                />
              );
            }
          }
          return <></>;
        })
      )}
    </div>
  );
};

export default Venmiga;
