import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header, { NavLink, NavLinks } from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({
  navs,
  notification,
  showNotification,
  heading,
  showHeading,
  slantedBackground,
  showSlantedBackground,
  primaryAction,
  showPrimaryAction,
  videoURL,
  showVideo,
  primaryActionLink,
  secondaryAction,
  secondaryActionLink,
  imageUrl,
}) => {
  const navLinks = [
    <NavLinks key={1}>
      {navs.map((nav) => (
        <NavLink key={nav.slug} href={nav.slug}>
          {nav.name}
        </NavLink>
      ))}
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">Hire Us</PrimaryLink>
    // </NavLinks>,
  ];

  if (notification == null) {
    notification = "We have now launched operations in Europe.";
  }

  if (showNotification == null) {
    showNotification = true;
  }

  if (heading == null) {
    heading = "Hire the best.";
  }

  if (showHeading == null) {
    showHeading = true;
  }

  if (slantedBackground == null) {
    slantedBackground = "Marketing Team.";
  }

  if (showSlantedBackground == null) {
    showSlantedBackground = true;
  }

  if (primaryAction == null) {
    primaryAction = "Read Customer Stories";
  }

  if (showPrimaryAction == null) {
    showPrimaryAction = true;
  }

  if (primaryActionLink == null) {
    primaryActionLink = "#";
  }

  if (secondaryAction == null) {
    secondaryAction = "About Us";
  }

  if (secondaryActionLink == null) {
    secondaryActionLink = "#";
  }

  if (videoURL == null) {
    videoURL =
      "//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1";
  }

  if (showVideo == null) {
    showVideo = true;
  }

  if (imageUrl == null) {
    imageUrl =
      "https://images.unsplash.com/photo-1551918120-9739cb430c6d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&width=1440&height=1024&q=75";
  }

  return (
    <>
      <Header links={navLinks} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              {heading}
              <span tw="text-primary-500">{slantedBackground}</span>
            </Heading>
            {showNotification && <Paragraph>{notification}</Paragraph>}
            {showPrimaryAction && (
              <PrimaryAction>{primaryAction}</PrimaryAction>
            )}
          </LeftColumn>
          <RightColumn>
            {showVideo && (
              <IllustrationContainer>
                <img
                  tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                  src={imageUrl}
                  alt="Design Illustration"
                />
              </IllustrationContainer>
            )}
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
